<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-if="!loading">
      <h5 class="text-center text-primary">Teacher Attendance</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Choose date*</strong><br/>
            <b-calendar v-model="value" @context="onContext" locale="en-US" :date-info-fn="dateClass" start-weekday="1"></b-calendar><br/>
            <div class="mt-3" />
            <strong><span class="text-primary">*</span> Sessions are planned on highlighted dates</strong>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 2: Input preference/attendance</strong>
            <b-row>
              <b-col>
                <div v-if="typeof(deadlineSubmissionDateTime[form.date]) !== 'undefined'">
                  <div v-if="isWithinDeadlineNow()">
                    <b-alert
                      variant="warning" 
                      show
                      >
                      Deadline submission {{deadlineSubmissionDateTime[form.date]['deadlineSubmissionDate']}}, {{deadlineSubmissionDateTime[form.date]['deadlineSubmissionTime']}} CET
                    </b-alert>

                    <b-form @submit.prevent="onSubmit" @reset.prevent>
                      <b-form-group>
                        <b-form-checkbox-group
                          :options="preferenceoptions"
                          v-model="form.preference"
                          name="roles-assignment"
                          stacked
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-button type="submit" variant="primary">Submit</b-button>
                    </b-form>
                  </div>
                  <div v-else>
                    <b-alert
                      variant="danger" 
                      show
                      >
                      Submission deadline has passed ({{deadlineSubmissionDateTime[form.date]['deadlineSubmissionDate']}}, {{deadlineSubmissionDateTime[form.date]['deadlineSubmissionTime']}} CET)
                    </b-alert>
                    <b-form-group>
                      <b-form-checkbox-group
                        :options="preferenceoptions"
                        v-model="form.preference"
                        name="roles-assignment"
                        stacked
                        disabled
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <div class="mt-3" />
      <b-alert 
        :show="dismissCountDown" 
        dismissible 
        variant="success" 
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
        >
        {{ alertMessage }}
      </b-alert>
      <b-alert
        :show="dismissErrCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissErrCountDown=0"
        @dismiss-count-down="countDownErrChanged"
      >
        {{ alertErrMessage }}
      </b-alert>
    </span>
    <div class="mt-3" />
  </div>
</template>

<script>
  import SessionService from '@/services/SessionService.js';
  import AttendanceService from '@/services/AttendanceService.js';

  export default {
    name: 'attendance',
    title: "TPA Gembira - attendance",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        alertErrMessage: "",
        modalShow: false,
        loading: false,
        value: '',
        context: null,
        dateWithSessionOptions: [],
        deadlineSubmissionDateTime: [],
        preferenceoptions: [ 
          { value: "online", text: "Teach online (skype/whatsapp session)"},
          { value: "classroom", text: "Teach offline (classroom meeting)"},
          { value: "absent", text: "Absent (not attend)"},
        ],
        form: {
          user_id: this.$auth.user.sub,
          family_name: this.$auth.user.family_name,
          given_name: this.$auth.user.given_name,
          nickname: this.$auth.user.nickname,
          email: this.$auth.user.email,
          picture: this.$auth.user.picture,
          date: "",
          preference: [],
        },
      }
    },
    created(){
      this.init();
    },
    methods: {
      isWithinDeadlineNow(){
        let now = new Date();
        let reference = new Date(this.deadlineSubmissionDateTime[this.form.date]['deadlineSubmissionDate'] + "T" + this.deadlineSubmissionDateTime[this.form.date]['deadlineSubmissionTime'] + "+01:00");
        return (now <= reference);
      },
      dateClass(ymd, date) {
        if (this.dateWithSessionOptions.includes(date.getFullYear()+ '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2))){
          return 'table-info';
        } else {
          return '';
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      onContext(ctx) {
        this.context = ctx;
        this.form.date = ctx.selectedYMD;
        if (ctx.selectedYMD !== null && ctx.selectedYMD !== ""){
          AttendanceService.getAttendanceDetails(ctx.selectedYMD, this.$auth.user.sub)
            .then(preferenceoptions => {
              this.form.preference = typeof(preferenceoptions.preference)==='undefined' ? [] : preferenceoptions.preference;
            });
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getDateWithSession()])
          .then(() => {
            this.loading = false;
          });
      },
      async getDateWithSession(){
        SessionService.getListSessions()
          .then(
            (list => {
              // users for visualization only
              for (let item of list){
                this.dateWithSessionOptions.push(item.date);
                this.deadlineSubmissionDateTime[item.date] = { 'deadlineSubmissionDate': item.deadlineSubmissionDate, 'deadlineSubmissionTime': item.deadlineSubmissionTime }
              }
            })
          );
      },
      onSubmit(){
        if (this.form.date == ""){
          this.showErrorConfirm("Please choose the date of attendance")
        } else {
          if (this.form.preference.length == 0){
            this.showErrorConfirm("Please choose at least one preference")
          } else {
            if (this.form.preference.length > 1 && this.form.preference.includes("absent")){
              this.showErrorConfirm("Absent preference cannot be combined with any teaching preference. Please revise.")
            } else {
              if (!this.dateWithSessionOptions.includes(this.form.date)){
                this.showErrorConfirm("Please choose only dates where TPA sessions are planned")
              } else {
                AttendanceService.upsertAttendanceDetails(this.form);
                this.showSuccessConfirm("Session update submitted successfully");
                this.dateWithSessionOptions.push(this.form.date);
                this.showSuccessConfirm("Update attendance submitted successfully");
              }
            }
          }
        }
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>